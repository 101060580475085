import { Navigate } from 'react-router-dom';
import routesConfig from '../routes/routesConfig';
import { jwtDecode } from 'jwt-decode';

const NextStepGuard = () => {
  const nextStep = localStorage.getItem('nextStep');
  const authResponse = localStorage.getItem('authResponse');
  
  if (!nextStep || !authResponse) {
    return <Navigate to={routesConfig.login} replace />;
  }

  const step = parseInt(nextStep, 10);
  const parsedAuthResponse = JSON.parse(authResponse);
  const accessToken = parsedAuthResponse?.accessToken || '';
  const routeParameters = parsedAuthResponse?.routeParameters || [];

  const parameter = getParameterByStep(step, accessToken, routeParameters);

  const targetRoute = getRouteByNextStep(step, parameter);

  return <Navigate to={targetRoute} replace />;
};

export const getRouteByNextStep = (step: number, parameter?: string) => {
  switch (step) {
    case 1:
      return `${routesConfig.validateEmail.replace(':sendCodeTo', parameter || '')}`;
    case 2:
      return routesConfig.categoryOnboarding;
    case 3:
      return routesConfig.serviceOnboarding;
    case 4:
      return routesConfig.localizationOnboarding;
    case 5:
      return routesConfig.businessOnboarding;
    case 6:
      return `${routesConfig.documentsOnboarding.replace(':documentType', parameter || '')}`;
    case 7:
      return routesConfig.subscriptionOnboarding;
    case 8:
      return routesConfig.termsAndConditions;
    case 9:
      return routesConfig.successCompletedOnboarding;
    default:
      return routesConfig.dashboard;
  }
};

const getParameterByStep = (step: number, accessToken: string, routeParameters: string[]) => {
  if (step === 1) {
    return extractEmailFromToken(accessToken);
  } else if (step === 6 && routeParameters.length > 0) {
    return routeParameters[0];
  }
  return '';
};

const extractEmailFromToken = (token: string): string => {
  try {
    const decoded: any = jwtDecode(token);
    return decoded.email || '';
  } catch (error) {
    console.error('Error decoding token:', error);
    return '';
  }
};

export const NextStepGuardRouteString = () => {
  const nextStep = localStorage.getItem('nextStep');
  const authResponse = localStorage.getItem('authResponse');
  const routeParameter = localStorage.getItem('routeParameters');
  
  if (!nextStep || !authResponse) {
    return routesConfig.login;
  }

  const step = parseInt(nextStep, 10);
  const parsedAuthResponse = JSON.parse(authResponse);
  const parsedrouteParameter = JSON.parse(routeParameter || '[]');
  const accessToken = parsedAuthResponse?.accessToken || '';
  const routeParameters = parsedAuthResponse?.routeParameters?? parsedrouteParameter ?? [];

  const parameter = getParameterByStep(step, accessToken, routeParameters);

  const targetRoute = getRouteByNextStep(step, parameter?? routeParameters?.length()>0 ?routeParameters[0]:'');

  return targetRoute;
};

export default NextStepGuard;
