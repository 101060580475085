import axiosInstance from '../../utils/axiosInstance';
import { API_ROUTES } from '../../constants/apiRoutes';

export interface CategorySearchResponse {
  categoryId: string;
  name: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  services: ServiceResponse[]; // Assuming there's a ServiceResponse interface for the services
}

export interface ServiceResponse {
  serviceId: string;
  serviceName: string;
}

// CategoryResponse interface (you can adjust it based on your API response)
export interface CategoryResponse {
  data: CategorySearchResponse[];
}

// Interface for the available services response
export interface CategoryServiceAvailableResponse {
  serviceId: string;
  serviceName: string;
}

// Fetch categories
const fetchCategories = async (): Promise<CategoryResponse> => {
  const response = await axiosInstance.get(API_ROUTES.CATEGORIES.FETCH_CATEGORIES, {
    params: {
      page: 1,
      pageSize: 1000,
    },
  });
  return response.data as CategoryResponse;
};

// Search categories by query
const searchCategories = async (query: string): Promise<CategorySearchResponse[]> => {
  const response = await axiosInstance.get(API_ROUTES.CATEGORIES.SEARCH_CATEGORIES, {
    params: { query },
  });
  return response.data as CategorySearchResponse[];
};

// Fetch available services for a professional
const fetchAvailableServices = async (): Promise<CategoryServiceAvailableResponse[]> => {
  try {
    const response = await axiosInstance.get(API_ROUTES.PROFESSIONAL.FETCH_AVAILABLE_SERVICES, {
      headers: {
        'accept': '*/*',
      },
    });

    if (response.status === 200) {
      const data: CategoryServiceAvailableResponse[] = response.data;
      return data;
    } else {
      throw new Error('Failed to load available services');
    }
  } catch (error) {
    throw new Error(`Error fetching services: ${error}`);
  }
};

export default {
  fetchCategories,
  searchCategories,
  fetchAvailableServices, // Add this to export the new function
};
