import { create } from 'zustand';
import onboardingService, { skipDocumentUpload, uploadDocument, XervixOnboardingResponse } from '../api/services/professionalService';
import { BusinessType } from '../types/businessType';
import axiosInstance, { axiosMultipartInstance } from '../utils/axiosInstance';

interface OnboardingStoreState {
  isLoading: boolean;
  error: string | null;
  nextStep: number | null;
  businessTypes: BusinessType[]; // Estado para guardar los tipos de negocio
  fetchBusinessTypes: () => Promise<void>; // Acción para cargar los tipos de negocio
  submitCategories: (categoryIds: string[]) => Promise<void>;
  submitServices: (serviceIds: string[]) => Promise<void>;
  updateLocation: (
    data: {
      departmentId?: number;
      departmentNameFromGM?: string;
      countryId?: number;
      countryNameFromGM?: string;
      cityId?: number;
      cityNameFromGM?: string;
      address?: string;
      locationRawGM?: string;
    },
    skipStep?: boolean
  ) => Promise<void>;
  upsertBusinessInfo: (data: {
    businessType: string;
    name: string;
    identification: string;
    phoneNumber: string;
    businessDescription: string;
  }) => Promise<void>;
  uploadDocuments: (
    logo?: File,
    files?: File[],
    documentTypes?: number[],
    skipStep?: boolean
  ) => Promise<void>;
  updateReferralEmail: (referralEmail?: string) => Promise<void>;
  acceptTermsAndConditions: (isAccepted: boolean) => Promise<void>;
  completeOnboarding: () => Promise<void>;
  uploadIndividualDocument: (file: File, documentType: number) => Promise<void>;
  skipUpload: () => Promise<void>;
}

const useOnboardingStore = create<OnboardingStoreState>((set) => ({
  isLoading: false,
  error: null,
  nextStep: localStorage.getItem('nextStep') ? parseInt(localStorage.getItem('nextStep') || '0', 10) : null, // Iniciar el store con el valor almacenado en localStorage
  businessTypes: [], // Inicializa el estado de tipos de negocio como un array vacío

  // Helper function to set nextStep and store it in localStorage
  setNextStep: (nextStep: number | null) => {
    set({ nextStep });
    if (nextStep !== null) {
      localStorage.setItem('nextStep', nextStep.toString());
    } else {
      localStorage.removeItem('nextStep');
    }
  },

  // Fetch Business Types
  fetchBusinessTypes: async () => {
    set({ isLoading: true, error: null });
    try {
      const businessTypes = await onboardingService.getBusinessTypes();
      set({ businessTypes, isLoading: false });
    } catch (error: any) {
      set({ error: error.message || 'Error fetching business types', isLoading: false });
    }
  },

  // Submit Categories
  submitCategories: async (categoryIds: string[]) => {
    set({ isLoading: true, error: null });
    try {
      const response: XervixOnboardingResponse = await onboardingService.submitCategories(categoryIds);
      set((state) => ({ nextStep: response.nextStep ?? state.nextStep, isLoading: false }));
      if (response.nextStep !== undefined) {
        localStorage.setItem('nextStep', response.nextStep.toString());
      }
    } catch (error: any) {
      set({ error: error.message || 'Error submitting categories', isLoading: false });
    }
  },

  // Submit Services
  submitServices: async (serviceIds: string[]) => {
    set({ isLoading: true, error: null });
    try {
      const response: XervixOnboardingResponse = await onboardingService.submitServices(serviceIds);
      set((state) => ({ nextStep: response.nextStep ?? state.nextStep, isLoading: false }));
      if (response.nextStep !== undefined) {
        localStorage.setItem('nextStep', response.nextStep.toString());
      }
    } catch (error: any) {
      set({ error: error.message || 'Error submitting services', isLoading: false });
    }
  },

  // Update Location
  updateLocation: async (data, skipStep = false) => {
    set({ isLoading: true, error: null });
    try {
      const response: XervixOnboardingResponse = await onboardingService.updateLocation(data, skipStep);
      set((state) => ({ nextStep: response.nextStep ?? state.nextStep, isLoading: false }));
      if (response.nextStep !== undefined) {
        localStorage.setItem('nextStep', response.nextStep.toString());
      }
    } catch (error: any) {
      set({ error: error.message || 'Error updating location', isLoading: false });
    }
  },

  // Upsert Business Info
  upsertBusinessInfo: async (data) => {
    set({ isLoading: true, error: null });
    try {
      const response: XervixOnboardingResponse = await onboardingService.upsertBusinessInfo(data);
      set((state) => ({ nextStep: response.nextStep ?? state.nextStep, isLoading: false }));
      if (response.nextStep !== undefined) {
        localStorage.setItem('nextStep', response.nextStep.toString());
      }
      if (response.routeParameters !== undefined) {
        localStorage.setItem('routeParameters', JSON.stringify(response.routeParameters));
      }

    } catch (error: any) {
      set({ error: error.message || 'Error updating business info', isLoading: false });
    }
  },

  // Upload Documents
  uploadDocuments: async (logo, files, documentTypes, skipStep = false) => {
    set({ isLoading: true, error: null });
    try {
      const response: XervixOnboardingResponse = await onboardingService.uploadDocuments(logo, files, documentTypes, skipStep);
      set((state) => ({ nextStep: response.nextStep ?? state.nextStep, isLoading: false }));
      if (response.nextStep !== undefined) {
        localStorage.setItem('nextStep', response.nextStep.toString());
      }
    } catch (error: any) {
      set({ error: error.message || 'Error uploading documents', isLoading: false });
    }
  },

  // Update Referral Email
  updateReferralEmail: async (referralEmail) => {
    set({ isLoading: true, error: null });
    try {
      const response: XervixOnboardingResponse = await onboardingService.updateReferalEmail(referralEmail);
      set((state) => ({ nextStep: response.nextStep ?? state.nextStep, isLoading: false }));
      if (response.nextStep !== undefined) {
        localStorage.setItem('nextStep', response.nextStep.toString());
      }
    } catch (error: any) {
      set({ error: error.message || 'Error updating referral email', isLoading: false });
    }
  },

  // Accept Terms and Conditions
  acceptTermsAndConditions: async (isAccepted) => {
    set({ isLoading: true, error: null });
    try {
      const response: XervixOnboardingResponse = await onboardingService.acceptTermsAndConditions(isAccepted);
      set((state) => ({ nextStep: response.nextStep ?? state.nextStep, isLoading: false }));
      if (response.nextStep !== undefined) {
        localStorage.setItem('nextStep', response.nextStep.toString());
      }
    } catch (error: any) {
      set({ error: error.message || 'Error accepting terms and conditions', isLoading: false });
    }
  },

  // Complete Onboarding
  completeOnboarding: async () => {
    set({ isLoading: true, error: null });
    try {
      const response: XervixOnboardingResponse = await onboardingService.completeOnboarding();
      set((state) => ({ nextStep: response.nextStep ?? state.nextStep, isLoading: false }));
      if (response.nextStep !== undefined) {
        localStorage.setItem('nextStep', response.nextStep.toString());
      }
    } catch (error: any) {
      set({ error: error.message || 'Error completing onboarding', isLoading: false });
    }
  },
  uploadIndividualDocument: async (file: File, documentType: number) => {
    set({ isLoading: true, error: null });
    try {
      const response = await uploadDocument(file, documentType);
      console.log('Document uploaded successfully:', response);
      localStorage.setItem('nextStep', response.nextStep.toString());
      set({ isLoading: false });
    } catch (error: any) {
      set({ error: error.message || 'Error uploading document', isLoading: false });
    }
  },

  // Función para omitir la subida de documentos
  skipUpload: async () => {
    set({ isLoading: true, error: null });
    try {
      const response = await skipDocumentUpload();
      console.log('Skipped document upload successfully:', response);
      set({ nextStep: response.nextStep, isLoading: false });
      localStorage.setItem('nextStep', response.nextStep.toString());

    } catch (error: any) {
      set({ error: error.message || 'Error skipping document upload', isLoading: false });
    }
  }

 
}));

export default useOnboardingStore;
