import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DashboardPage from './pages/DashboardPage';
import CategoryOnboardingPage from './pages/CategoryOnboardingPage';
import ServiceOnboardingPage from './pages/ServiceOnboardingPage';
import LocalizationOnboardingPage from './pages/LocalizationOnboardingPage';
import BusinessOnboardingPage from './pages/BusinessOnboardingPage';
import DocumentsOnboardingPage from './pages/DocumentsOnboardingPage';
import SubscriptionOnboardingPage from './pages/SubscriptionOnboardingPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import SuccessOnboardingPage from './pages/SuccessOnboardingPage';
import NextStepGuard from './guards/NextStepGuard';
import routesConfig from './routes/routesConfig';
import CamiloPage from './pages/CamiloPage';
import CreateAccountPage from './pages/CreateAccountPage';
import LandingProfessionalPage from './pages/LandingProfessionalPage';
import ProposalsPage from './pages/ProposalsPage';
import ReelsPage from './pages/ReelsPage';
import RequestsPage from './pages/RequestsPage';
import ServicesPage from './pages/ServicesPage';
import SignupPage from './pages/SignupPage';
import SplashPage from './pages/SplashPage';
import './i18n';
import LoginPage from './pages/LoginPage';
import VerifyCodePage from './pages/VerifyCodePage';
import ViewTermsAndConditionsPage from './pages/ViewTermsAndConditions';

const App = () => {
  return (
    <Router>
    <Routes>
      <Route path={routesConfig.splash} element={<SplashPage />} />
      <Route path={routesConfig.login} element={<LoginPage />} />
      <Route path={routesConfig.welcome} element={<LandingProfessionalPage />} />
      <Route path={routesConfig.dashboard} element={<DashboardPage />} />
      <Route path={routesConfig.validateEmail} element={<VerifyCodePage />} />
      <Route path={routesConfig.categoryOnboarding} element={<CategoryOnboardingPage />} />
      <Route path={routesConfig.serviceOnboarding} element={<ServiceOnboardingPage />} />
      <Route path={routesConfig.signup} element={<SignupPage />} />
      <Route path={routesConfig.createAccount} element={<CreateAccountPage />} />
      <Route path={routesConfig.localizationOnboarding} element={<LocalizationOnboardingPage />} />
      <Route path={routesConfig.subscriptionOnboarding} element={<SubscriptionOnboardingPage />} />
      <Route path={routesConfig.documentsOnboarding} element={<DocumentsOnboardingPage />} />
      <Route path={routesConfig.termsAndConditions} element={<TermsAndConditionsPage />} />
      <Route path={routesConfig.successCompletedOnboarding} element={<SuccessOnboardingPage />} />
      <Route path={routesConfig.mainRequests} element={<RequestsPage />} />
      <Route path={routesConfig.myProposals} element={<ProposalsPage />} />
      <Route path={routesConfig.myReels} element={<ReelsPage />} />
      <Route path={routesConfig.camilo} element={<CamiloPage />} />
      <Route path={routesConfig.mainServices} element={<ServicesPage />} />
      <Route path={routesConfig.landingProfessionalPage} element={<LandingProfessionalPage />} />
      <Route path={routesConfig.subscriptionProfessionalPage} element={<SubscriptionOnboardingPage />} />
      <Route path={routesConfig.businessOnboarding} element={<BusinessOnboardingPage />} />
      <Route path={routesConfig.termsAndConditionsPage} element={<ViewTermsAndConditionsPage />} />
      <Route path="*" element={<NextStepGuard />} />
    </Routes>
  </Router>
  );
};

export default App;
