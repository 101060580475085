const routesConfig = {
    splash: '/splash',
    login: '/login',
    welcome: '/welcome',
    dashboard: '/dashboard',
    validateEmail: '/validate-email/:sendCodeTo',
    businessOnboarding: '/business-onboarding-form-step',
    categoryOnboarding: '/category-onboarding-form-step',
    serviceOnboarding: '/service-onboarding-form-step',
    signup: '/signup',
    createAccount: '/create-account-page',
    localizationOnboarding: '/localization-onboarding-page',
    subscriptionOnboarding: '/subscription-onboarding-page',
    documentsOnboarding: '/documents-onboarding-page/:documentType',
    termsAndConditions: '/terms-and-conditions-onboarding-page',
    successCompletedOnboarding: '/success-completed-onboarding-page',
    mainRequests: '/main-requests',
    myProposals: '/mis-propuestas',
    myReels: '/myreels',
    camilo: '/camilo',
    mainServices: '/services',
    landingProfessionalPage: '/landing-page-professional-page',
    subscriptionProfessionalPage: '/subscription-professional-page',
    termsAndConditionsPage: '/terms-and-conditions-xervix',
  };
  export default routesConfig;
  