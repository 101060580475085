import axios from 'axios';
import { API_ROUTES } from '../../constants/apiRoutes';

const BASE_URL = process.env.REACT_APP_API_URL;

interface LoginRequest {
  email: string;
  password: string;
}

interface RegisterRequest {
  email: string;
  password: string;
  userType: number | null;
}

interface VerifyCodeRequest {
  code: string;
  emailOrPhone: string;
}

interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  nextStep: number;
}

// Login function
const login = async (email: string, password: string): Promise<LoginResponse> => {
  try {
    const response = await axios.post<LoginResponse>(`${BASE_URL}${API_ROUTES.ACCOUNT.LOGIN}`, { email, password }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      throw new Error('Unauthorized');
    }
    throw error;
  }
};

// Register function
const register = async (data: RegisterRequest): Promise<LoginResponse> => {
  try {
    const response = await axios.post<LoginResponse>(`${BASE_URL}${API_ROUTES.ACCOUNT.REGISTER}`, data, {
      headers: { 'Content-Type': 'application/json', accept: '*/*' },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// Reset password function
const resetPassword = async (email: string): Promise<void> => {
  try {
    const response = await axios.post(`${BASE_URL}${API_ROUTES.ACCOUNT.RESET_PASSWORD}`, { email }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      throw new Error('Email not found');
    }
    throw error;
  }
};

// Verify code function
const verifyCode = async (code: string, emailOrPhone: string): Promise<void> => {
  try {
    const response = await axios.post(`${BASE_URL}${API_ROUTES.ACCOUNT.VERIFY_CODE}`, { code, emailOrPhone }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 400) {
      throw new Error('Invalid code');
    }
    throw error;
  }
};

// Resend verification code function
const resendVerificationCode = async (): Promise<void> => {
  try {
    const response = await axios.post(`${BASE_URL}${API_ROUTES.ACCOUNT.RESEND_VERIFICATION_CODE_EMAIL}`, {}, {
      headers: { accept: '*/*' },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export default { login, register, resetPassword, verifyCode, resendVerificationCode };
