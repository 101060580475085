import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input'; // Importar el componente de teléfono
import 'react-phone-number-input/style.css'; // Estilo para PhoneInput
import useOnboardingStore from '../store/useOnboardingStore'; // Importamos el store
import routesConfig from '../routes/routesConfig'; // Asegúrate de tener configurado correctamente
import { NextStepGuardRouteString } from '../guards/NextStepGuard';

const BusinessOnboardingPage: React.FC = () => {
  const { t } = useTranslation();
  const {
    businessTypes,
    fetchBusinessTypes,
    upsertBusinessInfo,
    isLoading,
    nextStep,
  } = useOnboardingStore();

  const [businessType, setBusinessType] = useState('');
  const [name, setName] = useState('');
  const [identification, setIdentification] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [businessDescription, setBusinessDescription] = useState('');
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  // Fetch business types when the component is mounted
  useEffect(() => {
    fetchBusinessTypes();
  }, [fetchBusinessTypes]);

  // Manejo del formulario cuando el usuario lo envía
  const handleSubmit = async () => {
    setError(null);

    // Validar que todos los campos estén llenos
    if (!businessType || !name || !identification || !phoneNumber || !businessDescription) {
      setError(t('businessOnboarding.fillAllFields'));
      return;
    }

    try {
      // Llamar a la función para actualizar la información del negocio
      await upsertBusinessInfo({
        businessType,
        name,
        identification,
        phoneNumber,
        businessDescription,
      });

      const redirectTo = NextStepGuardRouteString();
      navigate(redirectTo); // Ajusta la redirección según tu configuración de rutas
    } catch (err) {
      setError(t('businessOnboarding.errorOnSubmit'));
    }
  };

  // Mostrar un estado de carga si aún no se ha obtenido la lista de tipos de negocio
  if (isLoading) {
    return <div className="text-center">{t('loading')}</div>;
  }

  return (
    <div className="min-h-screen p-8 bg-white flex items-center justify-center">
      <div className="w-full max-w-4xl mx-auto text-center">
        <h1 className="text-3xl font-bold mb-6">
          {t('businessOnboarding.title')}
        </h1>

        {/* Muestra el error si existe */}
        {error && <div className="text-red-500 mb-4">{error}</div>}

        <div className="grid grid-cols-2 gap-8">
          {/* Columna izquierda */}
          <div>
            {/* Tipo de sociedad */}
            <div className="mb-6">
              <label htmlFor="businessType" className="block text-left mb-2">
                {t('businessOnboarding.businessType')}
              </label>
              <select
                id="businessType"
                value={businessType}
                onChange={(e) => setBusinessType(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg"
              >
                <option value="">{t('businessOnboarding.selectBusinessType')}</option>
                {businessTypes.map((type) => (
                  <option key={type.businessTypeId} value={type.businessTypeId}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Nombre de la empresa */}
            <div className="mb-6">
              <label htmlFor="name" className="block text-left mb-2">
                {t('businessOnboarding.name')}
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg"
                placeholder={t('businessOnboarding.namePlaceholder')}
              />
            </div>

            {/* Número de identificación */}
            <div className="mb-6">
              <label htmlFor="identification" className="block text-left mb-2">
                {t('businessOnboarding.identification')}
              </label>
              <input
                type="text"
                id="identification"
                value={identification}
                onChange={(e) => setIdentification(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg"
                placeholder={t('businessOnboarding.identificationPlaceholder')}
              />
            </div>

            {/* Número de teléfono con indicativo */}
            <div className="mb-6">
              <label htmlFor="phoneNumber" className="block text-left mb-2">
                {t('businessOnboarding.phoneNumber')}
              </label>
              <PhoneInput
                international
                defaultCountry="CO" // País por defecto
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e as string)} // Ajusta el valor del teléfono
                className="w-full px-4 py-2 border rounded-lg"
              />
            </div>
          </div>

          {/* Columna derecha */}
          <div>
            {/* Descripción de la empresa */}
            <div className="mb-6 h-full">
              <label htmlFor="businessDescription" className="block text-left mb-2">
                {t('businessOnboarding.businessDescription')}
              </label>
              <textarea
                id="businessDescription"
                value={businessDescription}
                onChange={(e) => setBusinessDescription(e.target.value)}
                className="w-full h-full px-4 py-2 border rounded-lg"
                placeholder={t('businessOnboarding.businessDescriptionPlaceholder')}
              />
            </div>
          </div>
        </div>

        {/* Botón de continuar */}
        <div className="mt-8">
          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className={`px-8 py-3 rounded-lg ${!businessType || !name || !identification || !phoneNumber || !businessDescription
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-red-500 text-white'
              }`}
          >
            {t('businessOnboarding.continue')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessOnboardingPage;
