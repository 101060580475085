import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuthStore from '../store/useAuthStore';
import routesConfig from '../routes/routesConfig';
import NextStepGuard, {NextStepGuardRouteString} from '../guards/NextStepGuard';

const SignupPage: React.FC = () => {
  const { register, isLoading, error } = useAuthStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  
  const [isPasswordLengthValid, setIsPasswordLengthValid] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [hasTouchedPassword, setHasTouchedPassword] = useState(false);

  useEffect(() => {
    if (password || confirmPassword) {
      setHasTouchedPassword(true);
    }
    validatePassword();
    validateConfirmPassword();
  }, [password, confirmPassword]);

  const validatePassword = () => {
    setIsPasswordLengthValid(password.length >= 6);
    setHasNumber(/\d/.test(password));
    setHasSymbol(/[!@#$%^&*(),.?":{}|<>]/.test(password));
  };

  const validateConfirmPassword = () => {
    setPasswordsMatch(password?.length > 0 && confirmPassword?.length > 0 && password === confirmPassword);
  };

  const isFormValid = () =>
    email && isPasswordLengthValid && hasNumber && hasSymbol && passwordsMatch;

  const handleSignup = async () => {
    if (isFormValid()) {
      await register(email, password, 1);
      const reditectTo = NextStepGuardRouteString();
      navigate(reditectTo);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-white">
      <div className="bg-white p-6 shadow-lg rounded-lg w-full max-w-md">
        <main className="flex flex-col items-center">
          <h1 className="text-2xl font-bold text-center mb-4">{t('signupPage.title')}</h1>
          <form className="w-full">
            <TextInput
              label={t('signupPage.emailLabel')}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('signupPage.emailPlaceholder')}
            />
            <TextInput
              label={t('signupPage.passwordLabel')}
              type={passwordVisible ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t('signupPage.passwordPlaceholder')}
              toggleVisibility={() => setPasswordVisible(!passwordVisible)}
              isPasswordVisible={passwordVisible}
            />
            <TextInput
              label={t('signupPage.confirmPasswordLabel')}
              type={confirmPasswordVisible ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder={t('signupPage.confirmPasswordPlaceholder')}
              toggleVisibility={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
              isPasswordVisible={confirmPasswordVisible}
            />
            <PasswordCriteria
              isPasswordLengthValid={isPasswordLengthValid}
              hasNumber={hasNumber}
              hasSymbol={hasSymbol}
              passwordsMatch={passwordsMatch}
              hasTouchedPassword={hasTouchedPassword}
            />
            <button
              type="button"
              onClick={handleSignup}
              className={`w-full py-3 rounded-lg text-lg mt-6 ${isFormValid() ? 'bg-red-500 text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
              disabled={!isFormValid() || isLoading}
            >
              {isLoading ? t('signupPage.loadingButton') : t('signupPage.submitButton')}
            </button>
            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
          </form>
        </main>
      </div>
    </div>
  );
};

const TextInput: React.FC<{
  label: string;
  type: string;
  value: string;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleVisibility?: () => void;
  isPasswordVisible?: boolean;
}> = ({ label, type, value, placeholder, onChange, toggleVisibility, isPasswordVisible }) => {
  return (
    <div className="mb-4 w-full">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="relative">
        <input
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-500"
        />
        {toggleVisibility && (
          <button type="button" onClick={toggleVisibility} className="absolute inset-y-0 right-0 px-3 py-2">
            {isPasswordVisible ? '🙈' : '👁️'}
          </button>
        )}
      </div>
    </div>
  );
};

const PasswordCriteria: React.FC<{
  isPasswordLengthValid: boolean;
  hasNumber: boolean;
  hasSymbol: boolean;
  passwordsMatch: boolean;
  hasTouchedPassword: boolean;
}> = ({ isPasswordLengthValid, hasNumber, hasSymbol, passwordsMatch, hasTouchedPassword }) => {
  const { t } = useTranslation();
  return (
    <ul className="text-sm text-gray-600 mb-4">
      <CriteriaItem text={t('signupPage.minLength')} isValid={isPasswordLengthValid} touched={hasTouchedPassword} />
      <CriteriaItem text={t('signupPage.hasNumber')} isValid={hasNumber} touched={hasTouchedPassword} />
      <CriteriaItem text={t('signupPage.hasSymbol')} isValid={hasSymbol} touched={hasTouchedPassword} />
      <CriteriaItem text={t('signupPage.passwordsMatch')} isValid={passwordsMatch} touched={hasTouchedPassword} />
    </ul>
  );
};

const CriteriaItem: React.FC<{ text: string; isValid: boolean; touched: boolean }> = ({ text, isValid, touched }) => {
  return (
    <li className="flex items-center">
      <span className="mr-2">
        {isValid ? (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <circle cx="12" cy="12" r="10" strokeWidth={2} />
          </svg>
        )}
      </span>
      <span className={'text-black'}>{text}</span>
    </li>
  );
};

export default SignupPage;


