export const API_ROUTES = {
    ACCOUNT: {
        LOGIN: '/Account/login',
        REGISTER: '/Account/register',
        RESET_PASSWORD: '/Account/reset-password',
        VERIFY_CODE: '/Account/verify-code',
        RESEND_VERIFICATION_CODE_EMAIL: '/Account/resend-verification-code-email',
      },
      CATEGORIES: {
        FETCH_CATEGORIES: '/Categories',
        SEARCH_CATEGORIES: '/Categories/search',
      },
      PROFESSIONAL: {
        SUBMIT_CATEGORIES: '/Professionals/bulk-categories',
        SUBMIT_SERVICES: '/Professionals/bulk-services',
        UPDATE_LOCATION: '/Professionals/update-location',
        UPSERT_BUSINESS_INFO: '/Professionals/upsert-business-info',
        UPLOAD_DOCUMENTS: '/ProfessionalDocuments/upload-documents',
        UPDATE_REFERAL_EMAIL: '/Professionals/update-referal-email',
        ACCEPT_TERMS_AND_CONDITIONS: '/Professionals/accept-terms-and-conditions',
        COMPLETE_ONBOARDING: '/Professionals/complete-onboarding',
        FETCH_AVAILABLE_SERVICES: '/Professionals/available-services', 
      
      },
      BUSINESS_TYPES: '/BusinessTypes',
};