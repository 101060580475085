import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import useOnboardingStore from '../store/useOnboardingStore'; // Importa tu store
import { useNavigate } from 'react-router-dom';
import routesConfig from '../routes/routesConfig'; // Asegúrate de que esté importado correctamente
import { NextStepGuardRouteString } from '../guards/NextStepGuard';

const LocalizationOnboardingPage: React.FC = () => {
  const { t } = useTranslation();
  const { updateLocation, isLoading } = useOnboardingStore();
  const [country, setCountry] = useState('Colombia'); // Precargamos "Colombia"
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [locationError, setLocationError] = useState<string | null>(null);
  const [permissionsGranted, setPermissionsGranted] = useState(true); // Estado para saber si se tienen los permisos de ubicación
  const navigate = useNavigate();

  useEffect(() => {
    // Verificamos si los permisos de ubicación están habilitados
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getLocationData(latitude, longitude);
        },
        (error) => {
          setLocationError(t('localizationOnboarding.locationError'));
          setPermissionsGranted(false); // No se tienen permisos
        }
      );
    } else {
      setLocationError(t('localizationOnboarding.geoNotSupported'));
      setPermissionsGranted(false); // No se tiene soporte de geolocalización
    }
  }, [t]);

  const getLocationData = async (lat: number, lng: number) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyChrd6n58jSLu3G7s1EbQQxiZqmDTl5aDg`
      );
      if (response.data.results.length > 0) {
        const addressComponents = response.data.results[0].address_components;
        const extractedState = addressComponents.find((component: any) =>
          component.types.includes('administrative_area_level_1')
        )?.long_name;
        const extractedCity = addressComponents.find((component: any) =>
          component.types.includes('locality')
        )?.long_name;

        setState(extractedState || '');
        setCity(extractedCity || '');
      }
    } catch (error) {
      console.error('Error al obtener los datos de la API de Google Maps:', error);
      setLocationError(t('localizationOnboarding.fetchLocationError'));
    }
  };

  const handleContinue = async () => {
    if (country && state && city && address) {
      try {
        await updateLocation({
          departmentNameFromGM: state,
          countryNameFromGM: country,
          cityNameFromGM: city,
          address,
        });

        const redirectTo = NextStepGuardRouteString();
        navigate(redirectTo); // Ajusta la redirección según tu configuración de rutas
      } catch (error) {
        console.error('Error al actualizar la ubicación:', error);
      }
    } else {
      setLocationError(t('localizationOnboarding.fillAllFields'));
    }
  };

  // Validar que todos los campos estén llenos para habilitar el botón "Continuar"
  const isButtonDisabled = !country || !state || !city || !address;

  return (
    <div className="min-h-screen p-8 bg-white flex items-center justify-center">
      <div className="w-full max-w-2xl mx-auto text-center">
    


        <h1 className="text-3xl font-bold mb-4">{t('localizationOnboarding.welcomeTitle')}</h1>
        <img src="/assets/img/location.png" alt="Location" className="w-24 h-24 mx-auto" />
        <p className="mb-6">
          {permissionsGranted
            ? t('localizationOnboarding.useLocation')
            : t('localizationOnboarding.manualInput')}
        </p>

        <div className="grid grid-cols-1 gap-4">
          <div>
            <label>{t('localizationOnboarding.country')}</label>
            <input
              type="text"
              value={country}
              readOnly
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>

          <div>
            <label>{t('localizationOnboarding.department')}</label>
            <input
              type="text"
              value={state}
              onChange={(e) => setState(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg"
              placeholder={t('localizationOnboarding.enterDepartment')}
            />
          </div>

          <div>
            <label>{t('localizationOnboarding.city')}</label>
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg"
              placeholder={t('localizationOnboarding.enterCity')}
            />
          </div>

          <div>
            <label>{t('localizationOnboarding.address')}</label>
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg"
              placeholder={t('localizationOnboarding.enterAddress')}
            />
          </div>

          {locationError && <p className="text-red-500">{locationError}</p>}

          <div className="flex justify-center mt-8">
            <button
              onClick={handleContinue}
              disabled={isButtonDisabled || isLoading}
              className={`px-8 py-3 rounded-lg ${
                isButtonDisabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-red-500 text-white'
              }`}
            >
              {t('localizationOnboarding.continueButton')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalizationOnboardingPage;
