import { create } from 'zustand';
import categoryService, { CategoryResponse, CategorySearchResponse } from '../api/services/categoryService';

interface CategoryStoreState {
  categories: CategoryResponse | null;
  searchResults: CategorySearchResponse[];
  availableServices: any[]; // Estado para almacenar los servicios disponibles
  isLoading: boolean;
  error: string | null;
  fetchCategories: () => Promise<void>;
  searchCategories: (query: string) => Promise<any[]>;
  fetchAvailableServices: () => Promise<void>; // Método para obtener los servicios disponibles
}

const useCategoryStore = create<CategoryStoreState>((set) => ({
  categories: null,
  searchResults: [],
  availableServices: [], // Inicializamos con un array vacío
  isLoading: false,
  error: null,

  // Método para obtener categorías
  fetchCategories: async () => {
    set({ isLoading: true });
    try {
      const categories = await categoryService.fetchCategories();
      set({ categories, isLoading: false });
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error: error.message, isLoading: false });
      } else {
        set({ error: 'An unknown error occurred', isLoading: false });
      }
    }
  },

  // Método para buscar categorías
  searchCategories: async (query: string): Promise<any[]> => {
    set({ isLoading: true });
    try {
      const results = await categoryService.searchCategories(query);
      set({ isLoading: false });
      return results || []; // Devuelve los resultados directamente
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error: error.message, isLoading: false });
      } else {
        set({ error: 'An unknown error occurred', isLoading: false });
      }
      return []; // Retorna un array vacío si hay error
    }
  },

  // Nuevo método para obtener los servicios disponibles
  fetchAvailableServices: async () => {
    set({ isLoading: true });
    try {
      const availableServices = await categoryService.fetchAvailableServices();
      set({ availableServices, isLoading: false });
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error: error.message, isLoading: false });
      } else {
        set({ error: 'An unknown error occurred', isLoading: false });
      }
    }
  },
}));

export default useCategoryStore;
