import axiosInstance, { axiosMultipartInstance } from '../../utils/axiosInstance'; // Importamos axiosInstance para manejar automáticamente el token
import { API_ROUTES } from '../../constants/apiRoutes';
import { BusinessType } from '../../types/businessType';

export interface XervixOnboardingResponse {
  nextStep?: number;
  success?: boolean;
  message?: string;
  routeParameters?: string[];
}

// Submit Categories
const submitCategories = async (categoryIds: string[]): Promise<XervixOnboardingResponse> => {
  const response = await axiosInstance.post(
    API_ROUTES.PROFESSIONAL.SUBMIT_CATEGORIES,
    categoryIds
  );
  
  const xervixResponse = response.data as XervixOnboardingResponse;
  if (xervixResponse?.nextStep) localStorage.setItem('nextStep', xervixResponse.nextStep.toString());
  return xervixResponse;
};

// Submit Services
const submitServices = async (serviceIds: string[]): Promise<XervixOnboardingResponse> => {
  const response = await axiosInstance.post(
    API_ROUTES.PROFESSIONAL.SUBMIT_SERVICES,
    serviceIds
  );
  
  const xervixResponse = response.data as XervixOnboardingResponse;
  if (xervixResponse?.nextStep) localStorage.setItem('nextStep', xervixResponse.nextStep.toString());
  return xervixResponse;
};

// Update Location
const updateLocation = async (
  data: {
    departmentId?: number;
    departmentNameFromGM?: string;
    countryId?: number;
    countryNameFromGM?: string;
    cityId?: number;
    cityNameFromGM?: string;
    address?: string;
    locationRawGM?: string;
  },
  skipStep = false
): Promise<XervixOnboardingResponse> => {
  const response = await axiosInstance.post(
    `${API_ROUTES.PROFESSIONAL.UPDATE_LOCATION}?skipStep=${skipStep}`,
    data
  );
  
  const xervixResponse = response.data as XervixOnboardingResponse;
  if (xervixResponse?.nextStep) localStorage.setItem('nextStep', xervixResponse.nextStep.toString());
  return xervixResponse;
};

// Upsert Business Info
const upsertBusinessInfo = async (data: {
  businessType: string;
  name: string;
  identification: string;
  phoneNumber: string;
  businessDescription: string;
}): Promise<XervixOnboardingResponse> => {
  const response = await axiosInstance.post(
    API_ROUTES.PROFESSIONAL.UPSERT_BUSINESS_INFO,
    data
  );
  
  const xervixResponse = response.data as XervixOnboardingResponse;
  if (xervixResponse?.nextStep) localStorage.setItem('nextStep', xervixResponse.nextStep.toString());
  return xervixResponse;
};

// Upload Documents
const uploadDocuments = async (
  logo?: File,
  files?: File[],
  documentTypes?: number[],
  skipStep = false
): Promise<XervixOnboardingResponse> => {
  const formData = new FormData();
debugger
  if (!skipStep) {
    if (logo) {
      formData.append('files', logo);
    }

    if (files && documentTypes) {
      files.forEach((file, index) => {
        formData.append('files', file);
        formData.append('documentTypes', documentTypes[index].toString());
      });
    }
  }

  const response = await axiosInstance.post(
    `${API_ROUTES.PROFESSIONAL.UPLOAD_DOCUMENTS}?skipStep=${skipStep}`,
    formData
  );

  const xervixResponse = response.data as XervixOnboardingResponse;
  if (xervixResponse?.nextStep) localStorage.setItem('nextStep', xervixResponse.nextStep.toString());
  return xervixResponse;
};

// Update Referral Email
const updateReferalEmail = async (referalEmail?: string): Promise<XervixOnboardingResponse> => {
  const response = await axiosInstance.patch(
    API_ROUTES.PROFESSIONAL.UPDATE_REFERAL_EMAIL,
    { referalEmail }
  );

  const xervixResponse = response.data as XervixOnboardingResponse;
  if (xervixResponse?.nextStep) localStorage.setItem('nextStep', xervixResponse.nextStep.toString());
  return xervixResponse;
};

// Accept Terms and Conditions
const acceptTermsAndConditions = async (isAccepted: boolean): Promise<XervixOnboardingResponse> => {
  const response = await axiosInstance.patch(
    API_ROUTES.PROFESSIONAL.ACCEPT_TERMS_AND_CONDITIONS,
    { isTermAndConditionAccepted: isAccepted }
  );

  const xervixResponse = response.data as XervixOnboardingResponse;
  if (xervixResponse?.nextStep) localStorage.setItem('nextStep', xervixResponse.nextStep.toString());
  return xervixResponse;
};

// Complete Onboarding
const completeOnboarding = async (): Promise<XervixOnboardingResponse> => {
  const response = await axiosInstance.post(
    API_ROUTES.PROFESSIONAL.COMPLETE_ONBOARDING,
    {}
  );

  const xervixResponse = response.data as XervixOnboardingResponse;
  if (xervixResponse?.nextStep) localStorage.setItem('nextStep', xervixResponse.nextStep.toString());
  return xervixResponse;
};

const getBusinessTypes = async (): Promise<BusinessType[]> => {
  try {
    const response = await axiosInstance.get(API_ROUTES.BUSINESS_TYPES);

    if (response.status === 200) {
      return response.data.map((json: any) => ({
        businessTypeId: json.businessTypeId,
        name: json.name,
      }));
    } else {
      throw new Error('Failed to load business types');
    }
  } catch (error) {
    console.error('Error fetching business types: ', error);
    throw new Error('Error fetching business types');
  }
};

export const uploadDocument = async (file: File, documentType: number) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('documentType', documentType.toString());

  try {
    const response = await axiosMultipartInstance.post('/ProfessionalDocuments/upload-document', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading document:', error);
    throw new Error('Failed to upload document');
  }
};

// Servicio para saltar la subida de documentos
export const skipDocumentUpload = async () => {
  try {
    const response = await axiosInstance.post('/ProfessionalDocuments/skip-upload-documents');
    return response.data;
  } catch (error) {
    console.error('Error skipping document upload:', error);
    throw new Error('Failed to skip document upload');
  }
};


export default {
  submitCategories,
  submitServices,
  updateLocation,
  upsertBusinessInfo,
  uploadDocuments,
  updateReferalEmail,
  acceptTermsAndConditions,
  completeOnboarding,
  getBusinessTypes,
  uploadDocument,
  skipDocumentUpload,
};
